import React from 'react';
import { ThemeProvider } from 'styled-components';
import Helmet from 'react-helmet';
import favicon from '../images/favicon.ico';
import 'bulma/css/bulma.css';
import GlobalStyle from '../styles/global';
import '@fortawesome/fontawesome-free/css/all.min.css';
import theme from '../styles/theme';
// import '../styles/globalStyles.css';
import config from '../utils/siteConfig';
import Menu from '../components/Menu';
import Footer from '../components/Footer';

const Template = ({ children }) => {
  return (
    <div className="siteRoot">
      <Helmet>
        <title>{config.siteTitle}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href={favicon} />
        <script src="https://cdn.usefathom.com/script.js" site="NBDLYDJJ" defer></script>
      </Helmet>

      <ThemeProvider theme={theme}>
        <>
          <Menu />
          <main>{children}</main>
          <Footer />
        </>
      </ThemeProvider>
      <GlobalStyle />
    </div>
  );
};

export default Template;
