import PostGroteskBookEOT from '../fonts/PostGrotesk-Book.eot';
import PostGroteskBookWOFF from '../fonts/PostGrotesk-Book.woff';
import PostGroteskBookSVG from '../fonts/PostGrotesk-Book.svg';

import PostGroteskBoldEOT from '../fonts/PostGrotesk-Bold.eot';
import PostGroteskBoldWOFF from '../fonts/PostGrotesk-Bold.woff';
import PostGroteskBoldSVG from '../fonts/PostGrotesk-Bold.svg';

import TiemposTextWebRegularEOT from '../fonts/TiemposTextWeb-Regular.eot';
import TiemposTextWebRegularWOFF from '../fonts/TiemposTextWeb-Regular.woff';

import TiemposTextWebRegularItalicEOT from '../fonts/TiemposTextWeb-RegularItalic.eot';
import TiemposTextWebRegularItalicWOFF from '../fonts/TiemposTextWeb-RegularItalic.woff';

import TiemposTextWebSemiboldEOT from '../fonts/TiemposTextWeb-Semibold.eot';
import TiemposTextWebSemiboldWOFF from '../fonts/TiemposTextWeb-Semibold.woff';

import TiemposTextWebSemiboldItalicEOT from '../fonts/TiemposTextWeb-SemiboldItalic.eot';
import TiemposTextWebSemiboldItalicWOFF from '../fonts/TiemposTextWeb-SemiboldItalic.woff';

export default {
  PostGroteskBookEOT,
  PostGroteskBookWOFF,
  PostGroteskBookSVG,
  PostGroteskBoldEOT,
  PostGroteskBoldWOFF,
  PostGroteskBoldSVG,
  TiemposTextWebRegularEOT,
  TiemposTextWebRegularWOFF,
  TiemposTextWebRegularItalicEOT,
  TiemposTextWebRegularItalicWOFF,
  TiemposTextWebSemiboldEOT,
  TiemposTextWebSemiboldWOFF,
  TiemposTextWebSemiboldItalicEOT,
  TiemposTextWebSemiboldItalicWOFF,
};
