import { createGlobalStyle } from 'styled-components';
import theme from './theme';
import fontFiles from './fonts';

const GlobalStyle = createGlobalStyle`
  /* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
  */
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
  }

  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  @media screen and (min-width: 35em) {
    html {
      margin-right: calc(-100vw + 100%);
      overflow-x: hidden;
    }
  }

  ol, ul, li {
    line-height: 1.125;
    margin-bottom: .75rem;
    font-size: 1rem;
    margin-left: 12px;
  }

  li::before {
    content: "— ";
    margin-left: -24px;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote::before, 
  blockquote::after,
  q::before,
  q::after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  * {
    box-sizing: border-box;
  }

  body {
    background-color: ${theme.colors.red};
    color: ${theme.colors.tan};
    font-family: PostGrotesk, Helvetica, sans-serif;
    font-weight: 400;
    text-rendering: geometricPrecision;
  }

  .container {
    max-width: 1152px;
  }

  .section {
    padding: 0 ${theme.gap};
    
    @media (max-width: ${theme.responsive.mobile}) {
      padding: 0 ${theme.gap};
   }
  }

  @keyframes fadeInDown {
    0% {
      transform: translate3d(0, -15px, 0);
      opacity: 0;
    }
    
    20% {
      opacity: 0;
    }

    100% {
      opacity: 1;
      transform: none;
    }
  }

  .reveal {
    animation-name: fadeInDown;
    animation-duration: 600ms;
    animation-fill-mode: both;
    animation-delay: 150ms;
    animation-timing-function: ease;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  button,
  input {
    font-family: inherit;
    font-size: inherit;
    background: none;
    border: none;
    outline: none;
    appearance: none;
    border-radius: 0;
    resize: none;
    &:focus {
      outline: none;
    }
    &:invalid {
      box-shadow: none;
    }
  }

  @font-face {
    font-family: "PostGrotesk";
    font-style: normal;
    font-weight: 400;
    src: url(${fontFiles.PostGroteskBookEOT}) format("eot"),
      url(${fontFiles.PostGroteskBookWOFF}) format("woff"),
      url(${fontFiles.PostGroteskBookSVG}) format("svg");
  }

  @font-face {
    font-family: "PostGrotesk";
    font-style: normal;
    font-weight: 500;
    src: url(${fontFiles.PostGroteskBoldEOT}) format("eot"),
      url(${fontFiles.PostGroteskBoldWOFF}) format("woff"),
      url(${fontFiles.PostGroteskBoldSVG}) format("svg");
  }

  @font-face {
    font-family: "TiemposTextWeb";
    font-style: normal;
    font-weight: 400;
    src: url(${fontFiles.TiemposTextWebRegularEOT}) format("eot"),
      url(${fontFiles.TiemposTextWebRegularWOFF}) format("woff");
  }

  @font-face {
    font-family: "TiemposTextWeb";
    font-style: italic;
    font-weight: 400;
    src: url(${fontFiles.TiemposTextWebRegularItalicEOT}) format("eot"),
      url(${fontFiles.TiemposTextWebRegularItalicWOFF}) format("woff");
  }

  @font-face {
    font-family: "TiemposTextWeb";
    font-style: normal;
    font-weight: 500;
    src: url(${fontFiles.TiemposTextWebSemiboldEOT}) format("eot"),
      url(${fontFiles.TiemposTextWebSemiboldWOFF}) format("woff");
  }

  @font-face {
    font-family: "TiemposTextWeb";
    font-style: italic;
    font-weight: 500;
    src: url(${fontFiles.TiemposTextWebSemiboldItalicEOT}) format("eot"),
      url(${fontFiles.TiemposTextWebSemiboldItalicWOFF}) format("woff");
  }

  html {
    font-size: 20px;
    line-height: 1.5;
    text-rendering: geometricPrecision;
    background-color: ${theme.colors.red};
  }

  @media (max-width: ${theme.responsive.mobile}) {
    html {
      font-size: 18px;
    }
  }

  strong {
    font-weight: 500;
    color: inherit;
  }

  hr {
    background-color: rgba(255,255,255,0.1);
    height: 1px;
    margin: 1rem 0;
  }

  p {
    line-height: 1.5;
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  .small {
    font-size: .75rem;
  }

  a {
    color: inherit;
    border-bottom: 1px solid ${theme.colors.tan};
    padding-bottom: 0;
    transition: all .15s ease;
  }

  a:hover,
  a:focus {
    color: ${theme.colors.white};
    text-decoration: none;
    opacity: .5;
  }

  h1 {
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.125;
    letter-spacing: -1px;
    margin-bottom: 3rem;
    font-family: TiemposTextWeb, Georgia, serif;

    @media (max-width: ${theme.responsive.mobile}) {
      font-size: 1.75rem;
    }
  }

  h2 {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.35;
    letter-spacing: -.5px;
    margin: 0 0 1rem 0;

    @media (max-width: ${theme.responsive.mobile}) {
      font-size: 1.25rem;
    }
  }

  h3 {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.35;
    letter-spacing: -.5px;
    margin-bottom: 1rem;

    @media (max-width: ${theme.responsive.mobile}) {
      font-size: 1.125rem;
    }
  }

  h5 {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: .5rem;
    color: ${theme.colors.tan};

    @media (max-width: ${theme.responsive.mobile}) {
      font-size: .825rem;
    }
  }

  blockquote p { 
    margin: 1rem 0;
  }

  blockquote footer {
    color: ${theme.colors.tan};
    margin-bottom: 1rem;
  }

  .is-aligned-baseline {
    align-items: baseline;
  }

  .anchor {
    border-bottom: none;
  }

  .twitter-tweet {
    margin-top: 2rem !important;
    margin-right: auto;
    margin-bottom: 2rem !important;
    margin-left: auto;
  }

  .gatsby-resp-image-background-image {
    background-image: none !important;
  }
`;
export default GlobalStyle;
