import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const Nav = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: 0;
  margin-left: 0;

  @media (max-width: ${props => props.theme.responsive.mobile}) {
    flex-wrap: wrap;
    justify-content: flex-end;
  }
`;

const Header = styled.header`
  padding-top: 4rem;
  padding-bottom: 2rem;

  @media (max-width: ${props => props.theme.responsive.mobile}) {
    padding-top: 2rem;
    padding-bottom: 0;
  }
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  font-weight: 500;
  color: ${props => props.theme.colors.tan};
  transition: all 0.1s ease;

  @media (max-width: ${props => props.theme.responsive.mobile}) {
    color: ${props => props.theme.colors.tan};
  }

  .wordmark {
    shape-rendering: auto;
    fill: ${props => props.theme.colors.tan};

    @media (max-width: ${props => props.theme.responsive.mobile}) {
      fill: ${props => props.theme.colors.tan};
      width: 60px;
    }
  }

  &:hover {
    color: ${props => props.theme.colors.secondary};
  }
`;

const MenuItem = styled.li`
  margin-right: 3rem;
  margin-left: 0;
  margin-bottom: 0;

  @media (max-width: ${props => props.theme.responsive.mobile}) {
    margin-right: 1rem;
  }

  &::before {
    content: none;
    margin-left: 0;
  }

  a {
    text-decoration: none;
    color: ${props => props.theme.colors.tan};
    font-weight: 500;
    border: 0;
    transition: all 0.1s ease;
    font-size: .85rem;

    @media (max-width: ${props => props.theme.responsive.mobile}) {
      color: rgba(${props => props.theme.colors.tan}, 0.9);
    }

    &:hover {
      color: ${props => props.theme.colors.white};
      opacity: 1;

      @media (max-width: ${props => props.theme.responsive.mobile}) {
        color: ${props => props.theme.colors.tan};
      }
    }

    &.active {
      color: ${props => props.theme.colors.tan};

      @media (max-width: ${props => props.theme.responsive.mobile}) {
        color: ${props => props.theme.colors.tan};
      }
    }
  }
`;

const Menu = () => {
  return (
    <Header>
      <section className="section">
        <div className="container">
          <div className="columns is-vcentered is-mobile">
            <div className="column is-one-fifth-tablet is-one-third-mobile">
              <Link to="/">
                <Logo>
                  <svg xmlns="http://www.w3.org/2000/svg" height="22" viewBox="0 0 136.54 32" className="wordmark">
                    <path className="logo-path" d="M8.19,0C2.58,0,0,3.58,0,8V24c0,4.4,2.59,8,8.19,8,5.35,0,7.94-3.58,7.94-8V20.42H10.78v3.75c0,2-.65,3.15-2.59,3.15-2.16,0-2.85-1.12-2.85-3.45V8.11c0-2.33.69-3.45,2.85-3.45,1.38,0,2.59,1.12,2.59,3.75v2.41h5.35V8.53C16.13,3.88,13.54,0,8.19,0Z"></path>
                    <path className="logo-path" d="M24.35.43,18.61,31.57h5.18l.95-6.9H30.6l1,6.9h5.18L31,.43ZM25.52,20,27.63,6.63h.09L29.83,20Z"></path>
                    <path className="logo-path" d="M56.78,9.62C56.78,3,53.72.43,47.72.43h-7.5V31.57h5.35V18.8a25.7,25.7,0,0,0,2.76-.13l3.8,12.89h5.43L53,17.46C54.84,16.65,56.78,14.62,56.78,9.62Zm-9.32,4.79h-1.9V4.84h1.9c2.89,0,4,1.08,4,4.79s-1.1,4.77-4,4.77Z"></path>
                    <path className="logo-path" d="M90.12,15.18c2.59-1,3.71-3.84,3.71-6.51,0-5.3-2.54-8.24-8.28-8.24H77.61V31.57h8.45c6.08,0,8.37-3.88,8.37-9C94.43,18.54,92.83,16.39,90.12,15.18ZM83,4.83h2c2.37,0,3.49,1.51,3.49,4.4,0,2.59-1.55,4-3.58,4H83ZM85.2,27.17H83V17.6h1.73c3.19,0,4.4,1.6,4.4,4.79s-1,4.78-3.89,4.78Z"></path>
                    <path className="logo-path" d="M131.21.43v23.5c0,2.29-.91,3.41-2.85,3.41s-2.85-1.12-2.85-3.41V.43h-5.35V23.72c0,5.35,3.1,8.28,8.19,8.28s8.19-2.93,8.19-8.28V.43Z"></path>
                    <polygon className="logo-path" points="60.94 4.73 64.2 4.73 64.2 27.2 60.94 27.2 60.94 31.57 72.79 31.57 72.79 27.2 69.55 27.2 69.55 4.73 72.79 4.73 72.79 0.43 60.94 0.43 60.94 4.73"></polygon>
                    <path className="logo-path" d="M107.15,0C101.54,0,99,3.58,99,8V24c0,4.4,2.59,8,8.19,8s8.19-3.58,8.19-8V8C115.34,3.58,112.76,0,107.15,0ZM110,23.89c0,2.33-.69,3.45-2.85,3.45s-2.85-1.12-2.85-3.45V8.11c0-2.33.69-3.45,2.85-3.45S110,5.78,110,8.11Z"></path>
                  </svg>
                </Logo>
              </Link>
            </div>
            <div className="column is-four-fifths-tablet is-two-thirds-mobile">
              <Nav>
                <MenuItem className="is-hidden-mobile">
                  <Link to="/" activeClassName="active">
                    UX Strategy & Design
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/contact/" activeClassName="active">
                    Contact
                  </Link>
                </MenuItem>
              </Nav>
            </div>
          </div>
        </div>
      </section>
    </Header>
  );
};

export default Menu;
