import React from 'react';
import styled from 'styled-components';
import EmailSignupForm from './EmailSignupForm';

const Wrapper = styled.div`
  border-top: 1px solid rgba(255,255,255, 0.2);
  padding-top: 4rem;
  padding-bottom: 4rem;

  @media (max-width: ${props => props.theme.responsive.mobile}) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
`;

const FooterItem = styled.div`
  h3 {
    font-size: 1.25rem;
    font-weight: 400;
    margin-bottom: 1.5rem;
    line-height: 1.25;

    @media (max-width: ${props => props.theme.responsive.mobile}) {
      font-size: 1rem;
      margin-bottom: 0;
      margin-top: 0;
    }
  }

  .contact-email {
    @media (max-width: ${props => props.theme.responsive.mobile}) {
      margin-top: 0;
      margin-bottom: 1rem;
    }
  }

  h4 {
    font-weight: 500;
    margin: 0 0 0.5rem 0;
    color: ${props => props.theme.colors.tan};

    @media (max-width: ${props => props.theme.responsive.mobile}) {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  li {
    font-size: 0.9rem;
    margin: 0 0 0.25rem;
    line-height: 1.5;
  }
`;

const Copyright = styled.span`
  display: block;
  margin-top: 2rem;
  font-size: 0.75rem;

  @media (max-width: ${props => props.theme.responsive.mobile}) {
    font-size: 0.85rem;
  }
`;

const Icons = styled.div`
  font-size: .75rem;
  letter-spacing: 2px;
`;

const Footer = () => (
  <section className="section">
    <Wrapper className="container">
      <div className="columns">
        <Icons className="column is-one-fifth hidden-mobile">
          <i className="fas fa-heart"></i> <i className="fas fa-eye"></i> <i className="fas fa-ghost"></i>
        </Icons>
        <FooterItem className="column is-three-fifths is-full-mobile">
          <h3>Join our occasional newsletter for thoughts about user experience, business, availability, and news.</h3>
          <EmailSignupForm />
        </FooterItem>
      </div>
      <div className="columns is-mobile">
        <div className="column is-one-fifth is-hidden-mobile"></div>
        <FooterItem className="column is-one-fifth-tablet is-half-mobile">
          <p>
            <a href="mailto:hello@caribou.co">hello@caribou.co</a>
          </p>
        </FooterItem>
        <FooterItem className="column is-four-fifths-tablet is-half-mobile">
          <p>
            <span className="is-hidden-mobile">Based in </span>Berlin & Winnipeg
          </p>
        </FooterItem>
      </div>
      <div className="columns">
        <Copyright className="column">&copy; Caribou</Copyright>
      </div>
    </Wrapper>
  </section>
);

export default Footer;
