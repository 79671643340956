import React, { Component } from 'react';
import styled from 'styled-components';

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

const Subscribe = styled.form`
  .input.email {
    background: transparent;
    color: ${props => props.theme.colors.tan};
    border: 2px solid ${props => props.theme.colors.tan};
    transition: 100ms ease-in-out;
    font-size: 1rem;
    box-shadow: none;
    padding-top: calc(0.375em + 2px);
    font-family: PostGrotesk, Arial, sans-serif;

    &:hover {
      background-color: rgba(235, 172, 165, .3);
    }

    &:focus,
    &:active {
      background: ${props => props.theme.colors.tan};
      color: ${props => props.theme.colors.red};
      outline: 0;
      box-shadow: none;
    }

    &::placeholder {
      color: ${props => props.theme.colors.tan} !important;
    }
  }

  .button {
    background-color: ${props => props.theme.colors.tan};
    color: ${props => props.theme.colors.red};
    font-size: 1rem;
    padding-top: calc(0.375em + 2px);
    font-family: PostGrotesk, Arial, sans-serif;

    &:hover {
      border-color: ${props => props.theme.colors.tan};
    }
  }

  @media (max-width: ${props => props.theme.responsive.mobile}) {
    margin-top: 1.5rem;
  }
`;

const Confirmation = styled.div`
  font-size: 1rem;
  display: block;
  height: 2.25em;
  line-height: 1.5;
  border: 2px solid ${props => props.theme.colors.tan};
  padding-bottom: calc(0.375em - 1px);
  padding-left: calc(0.625em - 1px);
  padding-right: calc(0.625em - 1px);
  padding-top: calc(0.375em);
  border-radius: .25rem;
`;

const ErrorMessage = styled.span`
  font-size: .8rem;
`;

class EmailSignupForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      subscribed: false,
      hasError: false,
      error: ''
    }
  }

  handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  handleSubmit = event => {
    this.setState({
      submitting: true
    });
    fetch('/?no-cache=1', {
      method: 'POST',
      headers:  { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name':  'subscribe', ...this.state }),
    })
      .then(this.handleSuccess)
      .catch(error => this.handleError(error));
    event.preventDefault();
  }

  handleSuccess = () => {
    this.setState({
      email: '',
      subscribed: true,
      hasError: false,
      submitting: false,
      error: ''
    })
  }

  handleError = error => {
    console.error(error);
    this.setState({
      hasError: true,
      submitting: false,
      error: error
    });
  }

  render() {
    if (this.state.subscribed) {
      return (
        <Confirmation><i className="fa fa-check" aria-hidden="true"></i> All set! Thanks for subscribing!</Confirmation>
      );
    } else {
      return (
        <Subscribe
          name="subscribe"
          onSubmit={this.handleSubmit}
          data-netlify="true"
          data-netlify-honeypot="bot"
        >
          <input type="hidden" name="subscribe" value="contact" />
          <p hidden>
            <label>
              Don’t fill this out:{' '}
              <input name="bot" onChange={this.handleInputChange} />
            </label>
          </p>
          <div className="field has-addons">
            <div className="control">
              <input
                name="email"
                type="email"
                placeholder="Email address"
                value={this.state.email}
                onChange={this.handleInputChange}
                required
                className="input email"
              />
            </div>
            <div className="control">
              <button type="submit" name="subscribe" className="button">{this.state.submitting ? 'Submitting' : 'Submit'}</button>
            </div>
          </div>
          {this.state.hasError && <ErrorMessage>
            <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> Something went wrong. Please try again.
          </ErrorMessage>}
        </Subscribe>
      );
    }
  }
}

export default EmailSignupForm;
