const theme = {
  colors: {
    base: '#25231b', // Black
    secondary: '#8F897C', // Medium Gray
    tertiary: '#F4AE23', // yellow
    highlight: '#5b8bf7', // Light Blue
    tan: '#ebdcd5',
    red: '#E55242',
    white: '#ffffff',
  },
  sizes: {
    maxWidth: '1050px',
    maxWidthCentered: '650px',
  },
  responsive: {
    mobile: '768px',
    tablet: '769px',
    desktop: '1024px',
    large: '70em',
  },
  gap: '1rem',
};

export default theme;
