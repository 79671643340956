module.exports = {
  siteTitle: 'Caribou',
  siteTitleAlt: 'Caribou | UX Strategy & Design', // This allows an alternative site title for SEO schema.
  publisher: 'Caribou', // Organization name used for SEO schema
  siteDescription:
    'We’re a user experience strategy and design consultancy that helps companies create better products and experiences.',
  siteUrl: 'https://caribou.co', // Site domain. Do not include a trailing slash! If you wish to use a path prefix you can read more about that here: https://www.gatsbyjs.org/docs/path-prefix/
  articlesPerPage: 6, // Number of articles shown on paginated pages
  articlesPerFirstPage: 7, // Number of articles shown on first "writing" page
  author: 'Caribou', // Author for RSS author segment and SEO schema
  authorUrl: 'https://caribou.co/', // URL used for author and publisher schema, can be a social profile or other personal site
  userTwitter: '@caribouco', // Change for Twitter Cards
  shortTitle: 'Caribou', // Used for App manifest e.g. Mobile Home Screen
  shareImage: '/logos/share.jpg', // Open Graph Default Share Image. 1200x1200 is recommended
  shareImageWidth: 900, // Change to the width of your default share image
  shareImageHeight: 600, // Change to the height of your default share image
  siteLogo: '/logos/logo-512.png', // Logo used for SEO, RSS, and App manifest
  backgroundColor: '#e9e9e9', // Used for Offline Manifest
  themeColor: '#121212', // Used for Offline Manifest
  copyright: 'Copyright © Caribou', // Copyright string for the RSS feed
};
